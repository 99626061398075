.errorPage {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  padding: 5rem 2rem;

  svg {
    height: 162px;
  }
}
